
@font-face {
	font-family: 'Gotham Rounded Book';
	src: url('../fonts/GothamRounded-Book.eot');
	src: url('../fonts/GothamRounded-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamRounded-Book.woff2') format('woff2'),
		url('../fonts/GothamRounded-Book.woff') format('woff'),
		url('../fonts/GothamRounded-Book.ttf') format('truetype'),
		url('../fonts/GothamRounded-Book.svg#GothamRounded-Book') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Rounded';
	src: url('../fonts/GothamRounded-Medium.eot');
	src: url('../fonts/GothamRounded-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamRounded-Medium.woff2') format('woff2'),
		url('../fonts/GothamRounded-Medium.woff') format('woff'),
		url('../fonts/GothamRounded-Medium.ttf') format('truetype'),
		url('../fonts/GothamRounded-Medium.svg#GothamRounded-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Rounded Light';
	src: url('../fonts/GothamRounded-Light.eot');
	src: url('../fonts/GothamRounded-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamRounded-Light.woff2') format('woff2'),
		url('../fonts/GothamRounded-Light.woff') format('woff'),
		url('../fonts/GothamRounded-Light.ttf') format('truetype'),
		url('../fonts/GothamRounded-Light.svg#GothamRounded-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

/* This stylesheet generated by Transfonter (http://transfonter.org) on November 4, 2016 9:10 AM */

@font-face {
	font-family: 'Gotham Rounded Bold';
	src: url('../fonts/GothamRounded-Bold.eot');
	src: url('../fonts/GothamRounded-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamRounded-Bold.woff2') format('woff2'),
		url('../fonts/GothamRounded-Bold.woff') format('woff'),
		url('../fonts/GothamRounded-Bold.ttf') format('truetype'),
		url('../fonts/GothamRounded-Bold.svg#GothamRounded-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Varela Round';
	src: url('../fonts/VarelaRound.eot');
	src: url('../fonts/VarelaRound.eot?#iefix') format('embedded-opentype'),
		url('../fonts/VarelaRound.woff2') format('woff2'),
		url('../fonts/VarelaRound.woff') format('woff'),
		url('../fonts/VarelaRound.ttf') format('truetype'),
		url('../fonts/VarelaRound.svg#VarelaRound') format('svg');
	font-weight: normal;
	font-style: normal;
}



